import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import styled from "styled-components";
import { DataContext } from "../../../contexts/DataContext";

const Main = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const SelectContainer = styled(Select)`
  width: 40%;
  margin: 0.5em;
  width: ${(props) => (props.setSize ? "128px" : "")};
`;

export default function SportSelect({ sportType, onChange }) {
  return (
    <Main>
      <SelectContainer
        value={{
          value: sportType,
          label: sportType,
        }}
        options={[
          { value: "football", label: "football" },
          { value: "hurling", label: "hurling" },
        ]}
        onChange={(e) => {
          onChange(e.value);
        }}
        placeholder="Select Sport"
      />
    </Main>
  );
}
