import React from "react";
import styled from "styled-components";
import { Button } from "../../../Theme/Hyper";

const Main = styled.div`
  display: flex;
  padding: 20px 20px;
  margin-top: auto;
  // position: absolute;
  bottom: 0px;
  right: 0px;
`;

const Saving = styled.div`
  display: flex;
  font-weight: 300;
`;
export default function SavePanel({
  onSave,
  onCancel,
  style,
  state = 0,
  showDelete = false,
  onDelete,
  showSaveAs = false,
  onSaveAs,
  saveLabel = "Save",
}) {
  return (
    <Main style={{ ...style, marginLeft: showDelete ? "" : "auto" }}>
      {state === 0 && (
        <>
          {showDelete && (
            <Button red style={{ marginRight: "auto" }} onClick={onDelete}>
              Delete
            </Button>
          )}
          <Button green onClick={onSave} enabled={state !== "SAVING"}>
            {saveLabel}
          </Button>
          {showSaveAs && (
            <Button green onClick={onSaveAs}>
              Save As
            </Button>
          )}

          <Button onClick={onCancel}>Cancel</Button>
        </>
      )}
      {state !== 0 && (
        <Saving>
          {state === "SAVED" && "Saved!"}
          {state === "SAVING" && "Saving..."}
        </Saving>
      )}
    </Main>
  );
}
