import React, { useContext } from "react";
import SavePanel from "../UI/Form/SavePanel";
import Modal from "../UI/Modal/Modal";
import Panel from "../UI/Panel/Panel";
import PanelContent from "../UI/Panel/PanelContent";
import PanelTitle from "../UI/Panel/PanelTitle";
import { read, utils } from "xlsx";
import { useState } from "react";
import Select from "react-select";
import styled from "styled-components";
import { DataContext } from "../../contexts/DataContext";

const SelectContainer = styled(Select)`
  width: 40%;
  margin: 0.5em;
  width: ${(props) => (props.setSize ? "128px" : "")};
`;

const Players = styled.div`
  display: flex;
  flex-direction: column;
`;

export default function ImportSquadModal({
  squad,
  team,
  season,
  onSave,
  onCancel,
}) {
  const [sheetNames, setSheetNames] = useState();
  const [sheets, setSheets] = useState();
  const [selectedSheet, setSelectedSheet] = useState();
  const [selectedTeam, setSelectedTeam] = useState();
  const [teams, setTeams] = useState();
  const [players, setPlayers] = useState();
  const { updatePlayer, addToSquad, updateSquadPlayer } =
    useContext(DataContext);

  return (
    <Modal
      style={{ width: "50%" }}
      show={true}
      onCancel={() => {
        onCancel();
      }}
    >
      <Panel style={{ marginTop: "-25%" }}>
        <PanelTitle title={"Import Squad"}></PanelTitle>
        <PanelContent style={{ flexDirection: "column" }}>
          <input
            type="file"
            onChange={(e) => {
              var reader = new FileReader();
              reader.onload = (e) => {
                const wb = read(e.currentTarget.result);
                setSheets(wb.Sheets);
                setSheetNames(wb.SheetNames);
              };

              reader.readAsArrayBuffer(e.currentTarget.files[0]);
            }}
          />
          {sheets && (
            <SelectContainer
              value={{ value: selectedSheet, label: selectedSheet }}
              options={sheetNames?.map((wb) => {
                return { value: wb, label: wb };
              })}
              onChange={(e) => {
                setSelectedSheet(e.value);
                let sheet = sheets[e.value];
                const rows = utils.sheet_to_json(sheet, { header: 1 });
                setTeams(rows[2]?.filter((r) => r !== "Team" && r));
              }}
              placeholder="Select sheet"
            />
          )}
          {selectedSheet && (
            <SelectContainer
              value={{ value: selectedTeam, label: selectedTeam }}
              options={teams?.map((wb) => {
                return { value: wb, label: wb };
              })}
              onChange={(e) => {
                setSelectedTeam(e.value);
                let sheet = sheets[selectedSheet];
                const rows = utils.sheet_to_json(sheet, { header: 1 });
                let index = rows[2]?.findIndex((r) => r === e.value);

                let players = rows?.slice(3, rows.length)?.map((r) => r[index]);

                setPlayers(
                  players
                    ?.filter((p) => p)
                    ?.map((p) => {
                      return {
                        first: p?.split(" ")?.[0] || "",
                        last: p?.substring(p?.indexOf(" ") + 1) || "",
                      };
                    })
                );
              }}
              placeholder="Select team"
            />
          )}
          <Players>
            {players?.map((player, index) => {
              return (
                <span>
                  {index + 1} - {player.first} {player.last}
                </span>
              );
            })}
          </Players>
        </PanelContent>

        <SavePanel
          saveLabel="Import"
          showDelete={false}
          onDelete={() => {}}
          onSave={() => {
            players?.forEach((player, index) => {
              let old_player = squad?.find((s) => s.shirt_number === index + 1);
              let promises = [];

              let new_player = {
                ...old_player?.player,
                first_name: player?.first,
                last_name: player?.last,
                first_name_upper: player?.first?.toUpperCase(),
                last_name_upper: player?.last?.toUpperCase(),
                short_name: player?.first[0] + ". " + player?.last,
                short_name_upper: (
                  player?.first[0] +
                  ". " +
                  player?.last
                ).toUpperCase(),
              };

              promises.push(
                updatePlayer(new_player).then((newPlayer) => {
                  debugger;
                  if (!old_player) {
                    return addToSquad({
                      playerId: newPlayer._id,
                      teamId: team._id,
                      seasonId: season,
                      shirt_number: index + 1,
                      position: player.position,
                      type: "player",
                    });
                  }
                })
              );
              Promise.all(promises)
                .then(() => {
                  onSave();
                })
                .catch(() => {
                  onSave();
                });
            });
          }}
          onCancel={() => {
            onCancel();
          }}
        ></SavePanel>
      </Panel>
    </Modal>
  );
}
