import React, { useCallback, useContext, useEffect, useState } from "react";
import { DataContext } from "../../contexts/DataContext";
import styled from "styled-components";
import { Link, useHistory, useParams } from "react-router-dom";
import CompetitionList from "../Competition/CompetitionList";
import SeasonList from "../Season/SeasonList";
import { UIContext } from "../../contexts/UIContext";
import { Button, ScrollBar } from "../../Theme/Hyper";
import Modal from "../UI/Modal/Modal";
import Panel from "../UI/Panel/Panel";
import PanelTitle from "../UI/Panel/PanelTitle";
import PanelContent from "../UI/Panel/PanelContent";
import SavePanel from "../UI/Form/SavePanel";
import FullTeamList from "../Team/FullTeamList";
const Main = styled.div`
  width: 250px;
  height: calc(100vh - 40px);
  border-right: 1px solid #dadde1;
  box-sizing: border-box;

  padding: 0.5rem;
  color: #606770;
  background-color: #fff;
  position: fixed;
  top: 112px;
  z-index: 1000;
  flex-direction: column;
`;
const Ul = styled.ul`
  transition: height var(--ifm-transition-fast)
    cubic-bezier(0.08, 0.52, 0.52, 1);
  margin: 0;
  list-style-type: none;
  padding-left: 0;
  overflow-y: auto;
`;
const Li = styled.li`
  margin-top: 1px;
  > ul {
    margin-left: 2em;
  }
`;
const A = styled(Link)`
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  color: ${(props) =>
    props.selected ? props.theme.highlightColour : "#606770"};
  background-color: ${(props) => (props.selectedBG ? "#dee2e6" : "")};
  cursor: pointer;
  display: flex;
  line-height: 20px;
  justify-content: space-between;
  text-decoration: none;
  margin: 0;
  padding: 0.375rem 1rem;
  font-weight: ${(props) => (props.bold ? 500 : 300)};
  :hover {
    background-color: #0000000d;
  }
`;

const Scroll = styled(ScrollBar)`
  max-height: calc(100vh - 300px);
  overflow-y: auto;
`;
export default function TeamList({ teams, onUpdate }) {
  const history = useHistory();
  const { seasonId, teamId } = useParams();
  const [selectedTeam, setSelectedTeam] = useState();
  const [teamsArray, setTeamsArray] = useState();
  const [selectedCompetition, setSelectedCompetition] = useState();
  const [selectedSeason, setSelectedSeason] = useState();
  const [seasons, setSeasons] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [showExistinTeamDialog, setShowExistingteamDialog] = useState();
  const { createTeam, addTeamToSeason, getSeason } = useContext(DataContext);

  useEffect(() => {
    getSeason().then((data) => {
      setSeasons(data);
      if (selectedCompetition) {
        let newSeason = data
          ?.filter((s) => s.competition?._id === selectedCompetition?._id)
          ?.sort((a, b) => {
            return (
              parseInt(b.name.split("/")[0]) - parseInt(a.name.split("/")[0])
            );
          })[0];
        setSelectedSeason(newSeason);
      }
    });
  }, [selectedCompetition, teams]);

  useEffect(() => {
    getTeams();
  }, [selectedSeason, teams]);

  useEffect(() => {
    if (seasonId && seasons?.length) {
      setSelectedSeason(seasons?.find((s) => s._id === seasonId));
      setSelectedCompetition(
        seasons?.find((s) => s._id === seasonId)?.competition
      );
    }
  }, [seasonId, seasons]);

  const getTeams = useCallback(() => {
    let newTeams = [];

    for (
      let i = 0;
      i < (selectedSeason?.teams ? selectedSeason?.teams.length : 0);
      i++
    ) {
      newTeams.push(teams?.find((t) => t._id === selectedSeason.teams[i]._id));
    }
    newTeams = newTeams.sort((a, b) => {
      var textA = a.name.toUpperCase();
      var textB = b.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    setTeamsArray(newTeams);
  }, [selectedSeason, teams]);

  const createNewTeam = useCallback(() => {
    createTeam({
      season: selectedSeason._id,
      data: { name: "Team " + (teamsArray.length + 1), variables: {} },
    }).then((team) => {
      setSelectedTeam(team._id);

      addTeamToSeason({ season: selectedSeason._id, team: team._id }).then(
        (data) => {
          onUpdate();
          history.push("/gaa/Teams/" + team._id + "/" + selectedSeason._id);
        }
      );
    });
  }, [selectedSeason, teamsArray, addTeamToSeason, createTeam, history]);

  function onSave({ teams }) {
    let promises = [];
    for (let i = 0; i < teams?.length; i++) {
      let team_id = teams[i];
      promises.push(
        addTeamToSeason({ season: selectedSeason._id, team: team_id })
      );
    }
    Promise.all(promises)?.then(() => {
      onUpdate();
      history.push("/gaa/Teams/" + teams[0] + "/" + selectedSeason._id);
    });
  }

  return (
    <Main>
      <CompetitionList
        selectedCompetition={selectedCompetition}
        onSelect={(comp) => {
          setSelectedCompetition(comp);
          history.push("/gaa/teams");
        }}
        style={{ margin: "1em" }}
      />
      <SeasonList
        selectedSeason={selectedSeason}
        onSelect={(season) => {
          setSelectedSeason(season);
          history.push("/gaa/teams");
        }}
        competition={selectedCompetition}
        style={{ margin: "1em" }}
        seasons={seasons}
      />
      {selectedSeason && (
        <Button
          style={{ marginBottom: "1em", marginRight: "0px" }}
          green
          onClick={() => {
            createNewTeam();
          }}
        >
          + Create Team
        </Button>
      )}
      {selectedSeason && (
        <Button
          style={{ marginBottom: "1em", marginRight: "0px" }}
          green
          onClick={() => {
            setShowExistingteamDialog(true);
          }}
        >
          + Add Existing Team
        </Button>
      )}

      <Scroll>
        <Ul>
          {teamsArray?.map((team) => {
            return (
              <Li>
                <A
                  to={"/gaa/Teams/" + team?._id + "/" + selectedSeason?._id}
                  selected={team?._id === teamId}
                  selectedBG={team?._id === teamId}
                  onClick={() => {
                    setSelectedTeam(team?._id);
                  }}
                >
                  {team?.name}
                </A>
              </Li>
            );
          })}
        </Ul>
      </Scroll>

      {showExistinTeamDialog && (
        <Modal
          style={{ width: "70%", height: "700px" }}
          show={showExistinTeamDialog}
          onCancel={() => {
            setShowExistingteamDialog(false);
          }}
        >
          <Panel>
            <PanelTitle title={"Select teams to add"}></PanelTitle>
            <PanelContent>
              <FullTeamList
                seasons={seasons}
                selectedTeams={selectedTeams}
                onTeamSelected={(team) => {
                  let newSelectedTeams = [...selectedTeams];
                  if (
                    newSelectedTeams?.findIndex((t) => t === team._id) === -1
                  ) {
                    newSelectedTeams.push(team?._id);
                  } else {
                    newSelectedTeams = newSelectedTeams.filter(
                      (t) => t !== team._id
                    );
                  }
                  setSelectedTeams(newSelectedTeams);
                }}
              />
            </PanelContent>
            <SavePanel
              showDelete={false}
              style={{ marginLeft: "auto" }}
              onSave={() => {
                onSave({ teams: selectedTeams });
                setShowExistingteamDialog(false);
              }}
              onCancel={() => {
                setShowExistingteamDialog(false);
              }}
            />
          </Panel>
        </Modal>
      )}
    </Main>
  );
}
