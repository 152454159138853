import React, { useContext, useState } from "react";
import moment, { updateLocale } from "moment";
import styled from "styled-components";
import { FiPlus } from "react-icons/fi";
import { Link } from "react-router-dom";
import { Button } from "../../Theme/Hyper";
import { DataContext } from "../../contexts/DataContext";
const Main = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 0.5rem;
`;
const Ul = styled.ul`
  transition: height var(--ifm-transition-fast)
    cubic-bezier(0.08, 0.52, 0.52, 1);
  margin: 0;
  list-style-type: none;
  padding-left: 0;
`;
const Li = styled.li`
  margin-top: 10px;
  display: flex;
  > ul {
    margin-left: 2em;
  }
`;
const A = styled(Link)`
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  color: ${(props) =>
    props.selected ? props.theme.highlightColour : "#606770"};
  background-color: ${(props) => (props.selectedBG ? "#0000000d" : "")};
  cursor: pointer;
  display: flex;
  line-height: 20px;
  justify-content: space-between;
  text-decoration: none;
  margin: 0;
  padding: 0.375rem 1rem;
  font-weight: ${(props) => (props.bold ? 500 : 300)};
  :hover {
    background-color: #0000000d;
  }
`;
const TeamName = styled.div`
  display: flex;
  min-width: 40%;
  justify-content: center;
  max-width: 40%;
  text-align: center;
`;
const Date = styled.div`
  display: flex;
  min-width: 40%;
  justify-content: center;
  /* margin-right: 80px; */
`;

export default function FixtureList({ fixtures, onDelete }) {
  return (
    <Main>
      <Ul>
        {fixtures
          ?.sort((a, b) => {
            if (a.competition?.order > b.competition?.order) {
              return 1;
            } else if (a.competition?.order < b.competition?.order) {
              return -1;
            }

            if (a.competition?._id > b.competition?._id) {
              return 1;
            } else if (a.competition?._id < b.competition?._id) {
              return -1;
            }

            let a_date = moment(a.date + " " + a.time);
            let b_date = moment(b.date + " " + b.time);
            // Else go to the 2nd item
            if (a_date < b_date) {
              return -1;
            } else if (a_date > b_date) {
              return 1;
            } else {
              // nothing to split them
              return 0;
            }
          })
          ?.map((fixture, index) => {
            return (
              <>
                {(index === 0 ||
                  fixtures[index - 1].competition._id !==
                    fixture.competition._id) && (
                  <div>{fixture?.competition?.name}</div>
                )}

                <Li key={fixture._id}>
                  <A to={"/gaa/fixtures/" + fixture._id}>
                    <TeamName>{fixture.home_team?.name}</TeamName>
                    {moment(fixture?.time, "HH:mmZ")?.format("HH:mm")}
                    <TeamName>{fixture.away_team?.name}</TeamName>
                  </A>
                  {/* <Button
                    onClick={() => {
                      onDelete(fixture._id);
                    }}
                  >
                    Delete
                  </Button> */}
                </Li>
              </>
            );
          })}
      </Ul>
    </Main>
  );
}
