import React, { useState, useEffect } from "react";

import Grid from "styled-components-grid";

import SavePanel from "../UI/Form/SavePanel";
import Modal from "../UI/Modal/Modal";
import Panel from "../UI/Panel/Panel";
import PanelContent from "../UI/Panel/PanelContent";
import PanelTitle from "../UI/Panel/PanelTitle";
import { Button } from "../../Theme/Hyper";
import styled from "styled-components";

const Content = styled.div`
  padding-bottom: 20px;
`;
export default function ConfirmModal({
  show,
  onConfirm,
  onCancel,
  title,
  children,
}) {
  return (
    <Modal
      style={{ width: "600px" }}
      show={show}
      onCancel={() => {
        onCancel();
      }}
    >
      <Panel style={{ height: "150px" }}>
        <PanelTitle title={title}></PanelTitle>
        <PanelContent style={{ flexDirection: "column" }}>
          {children && <Content>{children}</Content>}
          <Grid>
            <Button onClick={onCancel}>Cancel</Button>{" "}
            <Button green onClick={onConfirm}>
              Confirm
            </Button>
          </Grid>
        </PanelContent>
      </Panel>
    </Modal>
  );
}
